<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div class="container">
      <!-- <div class="text-center mt-2">
      <h4>JOKER168S GAMES</h4>
    </div> -->
      <!-- <div class="bg1 p-0">
        <div class="text-center">
          <b-img
            fluid
            width="240px"
          />
        </div>

        <div class="text-center">
          <span class="text-white">
            <i class="fad fa-user-alt fa-sm" />

            {{ userData.username }}
          </span>
        </div>
        <div class="text-center">
          <span class="text-white">
            <i class="fad fa-wallet fa-sm" />

            เครดิต ฿{{
              Number(balance.balance)
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }}
            <span
              style="font-size: 0.95rem"
              @click="refresh"
            >
              <i
                data-v-fae5bece=""
                class="fad fa-redo fa-xs"
              /></span>
          </span>
        </div>

        <div>
          <div class="marquee p-0">
            <marquee
              behavior="asdasd"
              direction=""
            >
              ขณะนี้เปิดให้บริการตามปกติแล้วค่ะ | Maintenance Completed -->
      <!-- ระบบกำลังปิดปรับปรุง กรุณารอสักครู่นะคะ | Under Maintenance -->
      <!-- </marquee>
          </div>
          <a class="all_message">
            <span
              class="txtall_message"
              style="left:-9px;"
            ><i class="fas fa-envelope" /> แจ้งเตือน</span>
          </a>
        </div>
        <br>
      </div> -->

      <!-- game -->
      <div class="row1 wp2">
        <!-- <div
          class="column1"
          @click="getgamelistall"
        >
          <div class="card1">
            <b-img
              class="icon-img2"
              src="/game/s.png"
              width="45"
            /> <br>
            ทั้งหมด
          </div>
        </div> -->
        <div
          class="column1"
          @click="getgamelistby('Slot')"
        >
          <div class="card1">
            <b-img
              class="icon-img2"
              src="/game/s-1.png"
              width="45"
            /><br>
            สล็อต
          </div>
        </div>
        <div
          class="column1"
          @click="getgamelistby('Fishing')"
        >
          <div class="card1">
            <b-img
              class="icon-img2"
              src="/game/gone-fishing.png"
              width="45"
            /><br>
            ยิงปลา
          </div>
        </div>
        <div
          class="column1"
          @click="getgamelistby('Bingo')"
        >
          <div class="card1">
            <b-img
              class="icon-img2"
              src="/game/BINGO.png"
              width="45"
            /><br>
            บิงโก
          </div>
        </div>
        <div
          class="column1"
          @click="getgamelistby('ECasino')"
        >
          <div class="card1">
            <b-img
              class="icon-img2"
              src="/game/s3.svg"
              width="45"
            /><br>
            อีเกมส์
          </div>
        </div>
        <!-- <div
          class="column1"
          @click="getgamelistby('Live')"
        >
          <div class="card1">
            <b-img
              class="icon-img2"
              src="/game/blackjackp.1112c.svg"
              width="45"
            /><br>
            คาสิโน
          </div>
        </div> -->
      </div>
      <!-- <iframe
        ref="iFrameGame"
        style="width:100%;height:100vh; position:relative;border:0px;outline:none;overflow:hidden"
        onload="ResizeIFrame()"
        src="http://gwc788.net/PlayGame?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOjYsImlhdCI6MTYyODk2OTYwOCwiZXhwIjoxNjI5MTQ5NjA4fQ.-hhGgOOrpvg3__kDgOuKHcFMHBfxpft9H2JYB9PdS1o&appid=TGFQ&gameCode=cuarr8e1ncebn&language=en&mobile=true&redirectUrl=https://play.joker168s.com/games"
      /> -->
      <div>
        <b-row class="pl-1 pr-1">
          <b-col
            v-for="(key, index) in game_list"
            :key="index.GameCode"
            md="4"
            cols="4"
            class="p-0"
            @click="playgame(key)"
          >
            <div class="listGame">
              <div class="item-game animated ng-scope zoomIn">
                <!-- <span class="new" />
            <span class="hot" /> -->
                <a>
                  <img
                    class="img-game img-responsive"
                    :src="`http:${key.Image1}`"
                  >
                </a>

                <div class="text-center">
                  <small class="small">{{ key.GameName }}</small>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {
  // BInputGroup,
  // BInputGroupPrepend,
  BRow,
  BCol,
  BImg,
  BOverlay,
  BIconController,
  // BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BImg,
    BRow,
    BCol,
    BOverlay,
    BIconController,
    // BInputGroupPrepend,
    // BInputGroup,
    // BFormInput,
  },
  data() {
    return {
      show: true,
      game_list: '',
      gamename: '',
      balance: '',
      token: localStorage.getItem('token'),
      userData: JSON.parse(localStorage.getItem('userData')),
      /* eslint-disable global-require */
    }
  },
  mounted() {
    // this.getgamelistall()
    this.getgamelistby('Slot')
    this.getbalance(this.userData.username)
    window.addEventListener(
      'message',
      e => {
        if (e == null || e.data == null) {
          return
        }
        // eslint-disable-next-line default-case
        switch (e.data.event) {
          case 'ReadyToPlay':
            this.ResizeIFrame()
            break
        }
      },
      false,
    )
  },
  methods: {
    ResizeIFrame() {
      this.$ref.iFrameGame.contentWindow.postMessage(
        {
          event: 'iFrameSize',
          innerWidth: window.innerWidth,
          clientWidth: document.body.clientWidth,
          innerHeight: window.innerHeight,
          clientHeight: document.body.clientHeight,
        },
        '*',
      )
    },
    getgamelistby(type) {
      // console.log(type)
      this.show = true
      this.game_list = ''
      const params = {
        type,
      }
      this.$http
        .get('https://api.ufabangkok.com/api/game/list', { params })
        .then(response => {
          // console.log(response.data.ListGames)
          this.show = false
          this.game_list = response.data
        })
        .catch(error => console.log(error))
    },
    getgamelistbyname(type) {
      this.show = true
      console.log(type)
      this.game_list = ''
      const params = {
        name: this.gamename,
      }
      this.$http
        .get('https://api.ufabangkok.com/api/game/findname', { params })
        .then(response => {
          // console.log(response.data.ListGames)
          this.show = false
          this.game_list = response.data
        })
        .catch(error => console.log(error))
    },
    getgamelistall() {
      this.game_list = ''
      this.$http
        .get('https://api.ufabangkok.com/api/game/list')
        .then(response => {
          // console.log(response.data.ListGames)
          this.show = false
          this.game_list = response.data
        })
        .catch(error => console.log(error))
    },
    getbalance(username) {
      this.show = true
      const params = {
        username,
      }
      this.$http
        .get('https://api.ufabangkok.com/api/balance/getbalance', { params })
        .then(response => {
          this.show = false
          this.balance = response.data
        })
        .catch(error => console.log(error))
    },
    refresh() {
      this.getbalance(this.userData.username)
      // this.today = moment(new Date()).format('DD/MM/YYYY')
      // this.timenow = moment(new Date()).format('HH:mm')
    },
    playgame(key) {
      // if (key.GameType === 'Live') {
      //   this.SwalError('ปิดปรับปรุง')
      // } else
      if (Number(this.balance.balance) <= 0) {
        this.SwalError('คุณมียอดเครดิตไม่เพียงพอสำหรับการเล่น กรุณาเติมเครดิต')
      } else {
        // if (localStorage.getItem('CheckClick') !== 'true') {
        //   this.SwalError(`กรุณารอ ${localStorage.getItem('counter')} วินาที`)
        // } else
        //  {
        // localStorage.setItem('CheckClick', false)
        // // setTimeout(() => {
        // //   localStorage.setItem('CheckClick', true)
        // // }, 20000)
        // let counter = 20
        // const interval = setInterval(() => {
        //   // console.log(counter)

        //   counter -= 1
        //   localStorage.setItem('counter', counter)

        //   if (counter < 0) {
        //     clearInterval(interval)
        //     localStorage.setItem('CheckClick', true)
        //   }
        // }, 1000)
        this.$router.push({ name: 'play', params: { id: key.GameCode } })
        // const token = localStorage.getItem('token')
        // if (key.GameType === 'Live') {
        //   window.open(
        //     `http://gwc788.net/PlayGame?token=${token}&appID=TGFQ&gameCode=${key.GameCode}`,
        //     '_blank',
        //   )
        // } else {
        //   window.open(
        //     `http://gwc788.net/playGame?token=${token}&appID=TGFQ&gameCode=${key.GameCode}&language=en&mobile=false&redirectUrl=https://play.joker168s.com/games`,
        //     '_blank',
        //   )
        // }
      }
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: 'Error!',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style scoped>
body {
  background-image: url('/bg-jk.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.column1 {
  float: left;
  width: 25%;
  font-size: 16px;
  color: #fff;
  padding: 0;
}

.row1 {
  margin: 0 -5px;
}

.row1:after {
  content: '';
  display: table;
  clear: both;
}
.card1 {
  padding: 1px;
  text-align: center;
}
.img1 {
  max-width: 80px;
}
i {
  color: #ffc800;
}
.icon-img {
  max-height: 47px;
}
.icon-img2 {
  max-height: 79px;
}
ul {
  margin: 0 !important;
  padding: 0;
}
.box_menu {
  display: flex;
  padding: 0 15px;
  text-align: center;
  color: #fff;
  font-size: 18px;
  overflow: hidden;
  width: 20%;
  justify-content: center;
}
.a .box_content1 {
  padding: 0 15px;
  text-align: left;
  color: #fff;
  font-size: 18px;
  overflow: hidden;
  width: 50%;
}
.box_content2 {
  padding: 0 15px;
  text-align: right;
  color: #fff;
  font-size: 18px;
  overflow: hidden;
  float: right;
  position: relative;
  width: 50%;
  top: -20px;
}
.all_message {
  background: linear-gradient(to bottom, #fae5ed, #e2e2e2);
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  padding: 0 15px;
  color: #000;
  height: 20px;
  overflow: hidden;
  width: 20%;
  float: right;
  position: relative;
  top: -20px;
}
.txtall_message {
  position: relative;
  font-size: 13px;
  color: #000 !important;
  white-space: nowrap;
  top: -6px;
  left: -5px;
}
.marquee {
  background: linear-gradient(to bottom, #cb356b, #bd3f32);
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  padding: 0 15px;
  text-align: center;
  color: #fff;
  height: 20px;
  font-size: 16px;
  overflow: hidden;
  width: 80%;
}
.bg1 {
  background: linear-gradient(180deg, rgb(28, 1, 40) 50%, rgb(52, 1, 77) 100%);
}
.wp2 {
  /* margin-top: -5px; */
  /* margin: 20px auto; */
  max-width: 640px;
  background: linear-gradient(#3d4246, #24282b);
  height: 90px;
  border-radius: 4px;
  border-top: 1px solid #53575c;
  border-left: 1px solid #53575c;
  /* box-shadow: 1px 1px #17191c, 0 0 10px #323338;
  box-shadow: 1px 1px 10px #d91f20; */
  padding: 8px;
  padding-bottom: 12px;
}
i b {
  margin-top: 20px;
  font-size: 24px;
}
.bgame {
  background-color: #140014;
  border: 1px solid;
  border-radius: 5px;
  box-shadow: 0px 0px 10px #6610f2;
}
.small {
  position: relative;
  text-align: center;
  color: #ffffff;
  font-size: 14px;
  /* text-shadow: 0 0 5px #0bf4f3, 0 0 10px #0bf4f3, 0 0 20px #0bf4f3,
    0 0 40px #0bf4f3, 0 0 80px #0bf4f3; */
}

.listGame {
  margin-top: 15px;
  margin-left: -5px;
  margin-right: -5px;
  display: flex;
  flex-flow: wrap;
}
.item-game {
  position: relative;
  width: 210px;
  display: inline-block;
  margin-bottom: 15px;
  animation-duration: 0.5s;
}
@media (max-width: 650px) and (min-width: 375px) {
  .item-game {
    width: 115px;
    height: auto;
    position: relative;
  }
}
@media (max-width: 650px) and (min-width: 375px) {
  .img-game {
    width: 100%;
    height: auto;
    position: relative;
  }
}
@media (max-width: 440px) {
  .img-game {
    width: 160px;
    height: 149px;
  }
}
@media (min-width: 360px) and (max-width: 400px) {
  .img-game {
    min-height: 95px;
  }
}
.zoomIn {
  animation-name: zoomIn;
}
.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}
.game-container .listGame .item-game .l-game {
  position: relative;
  display: block;
}
.l-game {
  position: relative;
  display: block;
}
.l-game:before {
  content: '';
  position: absolute;
  width: 192px;
  height: 138px;
  top: 41px;
  left: 9px;
  position: absolute;
  background: url(http://www.joker123.net/Themes/Joker/mobile/assets/images/box-game.png?v=17)
    no-repeat;
  background-size: 191px 137px;
}
.new {
  position: absolute;
  width: 67px;
  max-width: 67px;
  height: 68px;
  background: url(http://www.joker123.net/Themes/Joker/mobile/assets/images/flag-new.png?v=17)
    no-repeat;
  top: 21%;
  right: 2%;
  z-index: 5;
}
.hot {
  position: absolute;
  width: 66px;
  max-width: 66px;
  height: 67px;
  background: url(http://www.joker123.net/Themes/Joker/mobile/assets/images/flag-hot.png?v=17)
    no-repeat;
  z-index: 5;
  left: 3.333%;
  top: 20.5%;
}
.img-game {
  position: relative;
  z-index: 2;
  width: 210px;
  height: 186px;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

@media (max-width: 650px) and (min-width: 375px) {
  .listGame {
    width: 100%;
  }
}
@media (max-width: 440px) {
  .game-container .listGame .item-game {
    width: 160px;
  }
}
@media (max-width: 580px) {
  .new {
    top: 21%;
    width: 31px;
    height: 32px;
    background-size: 30px 32px;
  }
}
@media (max-width: 480px) {
  .hot {
    left: 2.8%;
    top: 20.5%;
  }
}
@media (max-width: 580px) {
  .hot {
    left: 3%;
    top: 21%;
    width: 30px;
    height: 31px;
    background-size: 30px 31px;
  }
}
</style>
